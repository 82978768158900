<template>
  <div class="works">
    <div class="container">
      <div class="box" v-for="(item, index) in imgList" :key="index">
        <div class="item">
          <div>
            <img :src="item.img" alt="" />
          </div>
        </div>
        <div class="uname">PHOTO BY @{{ item.nickName }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Works",
  props: {
    imgList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.works {
  .container {
    column-count: 3;
    column-gap: 20;
    .box {
      margin-bottom: 50px;
      text-align: center;
      break-inside: avoid;
      .item {
        width: 400px;
        border-radius: 30px;
        text-align: center;
        border: 12px solid rgba(40, 40, 40, 100);
        box-sizing: border-box;
        margin-bottom: 20px;
        div {
          width: 100%;
          border-radius: 15px;
          padding: 38px;
          background-color: #fff;
          box-sizing: border-box;
          img {
            width: 100%;
          }
        }
      }
      .uname {
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
@media screen and(max-width:992px) {
  .works {
    margin: 0px 20px;
    .container {
      column-count: 2;
      .box {
        margin-bottom: 20px;
        .item {
          margin-right: 0px;
          width: 100%;
          border-radius: 10px;
          border: 5px solid rgba(40, 40, 40, 100);
          margin-bottom: 10px;
          div {
            width: 100%;
            border-radius: 5px;
            padding: 15px;
            background-color: #fff;
            box-sizing: border-box;
            img {
              width: 100%;
            }
          }
        }
        .uname {
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
</style>