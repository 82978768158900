<template>
  <div class="home">
    <!-- 标题 -->
    <div class="header">
      <img src="../assets/image/camera.png" alt="" />
      <div class="title">壹摄网</div>
      <div>F1RSTPHOTO</div>
    </div>
    <div class="main">
      <!-- 大图 -->
      <div class="banner">
        <img class="img1" src="../assets/image/setting.png" alt="" />
        <img class="img2" src="../assets/image/op.png" alt="" />
        <div class="msg">
          <div>Of course，we records.</div>
          <div>当然要记录啊</div>
        </div>
      </div>
      <!-- 二维码 -->
      <div class="qrcode">
        <img src="../assets/image/qrcode.png" alt="" />
        <div class="title">
          <div>微信扫一扫</div>
          <div>进入小程序</div>
        </div>
      </div>
      <!-- 近期活动 -->
      <div class="headline">
        <div>近期活动</div>
        <div>Recent Activity</div>
      </div>
      <!-- 轮播图 -->
      <div class="swiper">
        <el-carousel trigger="click" height="499px">
          <el-carousel-item v-for="(item, index) in swiperList" :key="index">
            <el-image
              :src="item.img"
              fit="cover"
              @click="headleNav(item.url)"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 优秀作品 -->
      <div class="excellent">
        <div>优秀作品展</div>
        <div>Exhibition Of Works</div>
      </div>
      <!-- 梯形 -->
      <div class="trapezoid"></div>
      <!-- 作品展示 -->
      <div class="midd">
        <Works :imgList="imgList" />
      </div>
      <!-- 关于我们 -->
      <div class="headline">
        <div>关于我们</div>
        <div>About F1RST</div>
      </div>
    </div>
    <nav-footer />
  </div>
</template>

<script>
import Works from "../components/Works";
import NavFooter from "../components/NavFooter";
export default {
  name: "Home",
  components: {
    Works,
    NavFooter,
  },
  data() {
    return {
      swiperList: [],
      imgList: [],
    };
  },
  mounted() {
    this.getDate();
  },
  methods: {
    async getDate() {
      let res = await this.$axios.get("gunwang/index");
      if (res.data.code !== 0) return;
      this.swiperList = res.data.data.recent_activity;
      this.imgList = res.data.data.exhibition_works;
    },

    headleNav(url) {
      window.location.href = url;
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  margin: 25px auto;
  width: 1240px;
  .header {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 28px;
    margin-bottom: 50px;
    img {
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .title {
      margin-right: 31px;
      position: relative;
      &::after {
        position: absolute;
        content: "";
        width: 1px;
        height: 28px;
        background-color: rgba(255, 255, 255, 100);
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .main {
    .banner {
      position: relative;
      width: 100%;
      height: 610px;
      .img1 {
        width: 100%;
        height: 100%;
      }
      .img2 {
        width: 1170px;
        height: 540px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .msg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        div:first-child {
          color: rgba(255, 73, 73, 100);
          font-size: 24px;
        }
        div:last-child {
          color: rgba(255, 255, 255, 100);
          font-size: 52px;
          margin-top: 5px;
        }
      }
    }
    .swiper {
      margin: 30px;
      text-align: center;
    }
    .qrcode {
      margin: 80px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 350px;
        height: 350px;
        margin-bottom: 35px;
      }
      .title {
        width: 240px;
        height: 50px;
        border: 1px solid rgba(244, 245, 245, 100);
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(244, 245, 245, 100);
        div:last-child {
          margin-left: 10px;
          color: rgba(255, 73, 73, 100);
        }
      }
    }
    .headline {
      text-align: center;
      div:first-child {
        position: relative;
        color: rgba(255, 255, 255, 100);
        font-size: 30px;
        margin-bottom: 5px;
        &::after {
          position: absolute;
          content: "";
          width: 500px;
          height: 1px;
          background-color: rgba(48, 48, 48, 100);
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
        }
        &::before {
          position: absolute;
          content: "";
          width: 500px;
          height: 1px;
          background-color: rgba(48, 48, 48, 100);
          top: 50%;
          left: 0px;
          transform: translateY(-50%);
        }
      }
      div:last-child {
        font-size: 17px;
        font-style: italic;
        color: rgba(255, 73, 73, 100);
      }
    }
    .excellent {
      text-align: center;
      color: rgba(255, 73, 73, 100);
      font-size: 17px;
      font-style: italic;
      div:first-child {
        margin-bottom: 5px;
        color: rgba(255, 255, 255, 100);
        font-size: 45px;
        font-style: normal;
      }
    }
    .trapezoid {
      border-top: 50px solid rgba(28, 28, 28, 100);
      border-left: 50px solid transparent;
      border-right: 50px solid transparent;
      height: 0;
      margin: 50px 0px 75px;
    }
    .midd {
      margin-bottom: 70px;
    }
  }
}
@media screen and(max-width:992px) {
  .home {
    width: 100%;
    .header {
      width: 100%;
      display: flex;
      justify-content: center;
      font-size: 16px;
      margin-bottom: 23px;
      img {
        width: 30px;
        height: 30px;
      }
      .title {
        margin-right: 21px;
        &::after {
          height: 16px;
          right: -8px;
        }
      }
    }
    .main {
      width: 100%;
      .banner {
        width: 90%;
        height: 142px;
        margin: auto;
        .img2 {
          width: 80%;
          height: 118px;
        }
        .msg {
          div:first-child {
            font-size: 10px;
          }
          div:last-child {
            font-size: 17px;
          }
        }
      }
      .qrcode {
        margin: 30px 0;
        img {
          width: 125px;
          height: 125px;
          margin-bottom: 15px;
        }
        .title {
          font-size: 12px;
          width: 155px;
          height: 34px;
          div:last-child {
            margin-left: 5px;
          }
        }
      }
      .headline {
        div:first-child {
          font-size: 16px;
          margin-bottom: 2px;
          &::after {
            width: 115px;
            right: 20px;
            top: 65%;
            transform: translateY(0%);
          }
          &::before {
            width: 115px;
            top: 65%;
            left: 20px;
            transform: translateY(0%);
          }
        }
        div:last-child {
          font-size: 10px;
        }
      }
      .swiper {
        margin: 15px 0px 25px;
      }
      .excellent {
        font-size: 10px;
        div:first-child {
          margin-bottom: 2px;
          font-size: 16px;
        }
      }
      .trapezoid {
        border-top: 20px solid rgba(28, 28, 28, 100);
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        height: 0;
        margin: 15px 0px;
      }
      .midd {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
